import React, { useState, useContext, useEffect } from "react";
import { NavLink, Link, useParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { GiSettingsKnobs } from "react-icons/gi";
import { HiMagnifyingGlass } from "react-icons/hi2";
import {
  InputGroup,
  Dropdown,
  Popover,
  OverlayTrigger,
  Form,
} from "react-bootstrap";
import LandLordCard from "../components/landlord/LandLordCard";
import Properties from "../components/landlord/Properties";
import { Ucontext } from "../context/Ucontext";
import { landlordLoadSharedProfiles } from "../context/functions/AdminFunction";
import toast from "react-hot-toast";

const LandLordDetail = () => {
  const { landlordid } = useParams();
  const {
    adminDispatch,
    adminState,
    getSingleUser,
    getLandlordProperties,
    getLandlordPropertyDetails,
    updateUserInfo,
  } = useContext(Ucontext);
  const { totalLandlords, totalProperties } = adminState;

  const [loading, setLoading] = useState(false);
  const [landlord, setLandlord] = useState({});
  const [properties, setProperties] = useState([]);
  const [filteredProp, setFilteredProp] = useState([]);
  const [propertiesDetails, setPropertiesDetails] = useState([]);
  const [sharedProfile, setSharedProfiles] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [iban_num, setIban_num] = useState("");
  const [showIban, setShowIban] = useState(false);

  useEffect(() => {
    loadLandlord();
    loadProperties();
  }, []);

  useEffect(() => {
    setIban_num(landlord?.iban_num);
  }, [landlord]);

  useEffect(() => {
    const temp = propertiesDetails.filter((property) => {
      const addr = `${property?.street} ${property?.street_number} ${property?.floor_number} ${property?.flat_number} ${property?.postcode} ${property?.muncipality}`;
      if (addr.toLowerCase().includes(searchVal.toLowerCase())) return property;
    });
    console.log(temp);
    setFilteredProp(temp);
  }, [searchVal]);

  const loadLandlord = async () => {
    setLoading(true);
    let landlord = await getSingleUser(landlordid, "landlord");
    setLandlord(landlord);
    setLoading(false);
  };

  const loadProperties = async () => {
    setLoading(true);
    let landlord = await getLandlordProperties(landlordid);
    let details = await getLandlordPropertyDetails(landlordid);
    let sharedProfile1 = await landlordLoadSharedProfiles(landlordid);
    console.log(sharedProfile1);
    setSharedProfiles(sharedProfile1);
    setProperties(landlord?.properties);
    setFilteredProp(details?.properties);
    setPropertiesDetails(details?.properties);
    setLoading(false);
  };

  const handleSearch = (e) => {
    setSearchVal(e.target.value);
  };

  const handleChangeIBAN = (e) => {
    setIban_num(e.target.value);
  };

  const saveIban = () => {
    updateUserInfo({ iban_num }, landlordid)
      .then((res) => {
        toast.success("Landlord Updated");
        setShowIban(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(landlord, "KALSJDKLASDJKLASDJKLSDJKLS");
  if (loading) return <h1>Loading...</h1>;
  return (
    <>
      <NavLink to="/landlord">
        <IoIosArrowBack color="var(--dark)" size={25} />
      </NavLink>
      <div className="property_text d-flex justify-content-between">
        <div className="header_table">
          <h1>Details</h1>
        </div>

        <div className="searchbar_table">
          <div>
            <InputGroup className="input_table_header">
              <InputGroup.Text id="basic-addon1">
                <HiMagnifyingGlass />
              </InputGroup.Text>
              <Form.Control
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon1"
                value={searchVal}
                onChange={handleSearch}
              />
            </InputGroup>
          </div>
        </div>
      </div>
      <div className="verticle_gap_in_components">
        <div>
          <input
            type="text"
            id="iban_num"
            name="iban_num"
            value={iban_num}
            onChange={handleChangeIBAN}
            disabled={!showIban}
          />
          {showIban ? (
            <>
              <button onClick={saveIban}>Save</button>
              <button onClick={(e) => setShowIban(false)}>Cancel</button>
            </>
          ) : (
            <button onClick={(e) => setShowIban(true)}>Edit</button>
          )}
        </div>
        <LandLordCard
          landlord={landlord}
          properties={properties}
          sharedProfile={sharedProfile}
        />
        <Properties sharedProfile={sharedProfile} properties={filteredProp} />
      </div>
    </>
  );
};

export default LandLordDetail;
