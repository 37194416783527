import React, { useEffect, useState, useContext } from 'react'
import { GiSettingsKnobs } from 'react-icons/gi'
import { HiMagnifyingGlass } from 'react-icons/hi2'
import { InputGroup, Dropdown, Popover, OverlayTrigger, Form } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import TenantsList from '../components/tenants/TenantsList';
import NonTenantsList from '../components/tenants/NonTenantsList';
import { Ucontext } from '../context/Ucontext';
import Loading from '../components/loader/Loading';



const NonTenants = () => {
    const {
        adminDispatch,
        getSingleUser,
        adminState,
        getAllTenants,

    } = useContext(Ucontext);
    const { totalTenants, totalProperties } = adminState;
    const [searchVal, setSearchVal] = useState('')
    const [currentPage, setcurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0)
    const [sortBy, setSortBy] = useState('Latest')

    const location = useLocation();
    // console.log(location.pathname);

    const [routeName, setRouteName] = useState();
    const [nonTenants, setNonTenants] = useState([]);
    const [loading, setLoading] = useState(false);
    // const [totalTenants, setTotalTenants] = useState([]);

    useEffect(() => {
        loadTenants(currentPage, searchVal, sortBy);
    }, [currentPage, searchVal, sortBy])

    useEffect(() => {
        checkRented();
    }, [totalTenants])



    const loadTenants = async (currentPage, searchVal, sortBy) => {
        setLoading(true);
        let tenants = await getAllTenants(currentPage, searchVal, 'not-rented', sortBy);
        if (tenants.status == "Success") {
            await adminDispatch({ type: "TENANTS_LOADED", payload: tenants?.tenants });

            setTotalPages(tenants.totalPages)
            //setTotalTenants(tenants?.tenants);
            checkRented();
        }
        setLoading(false);
    }


    const checkRented = async () => {
        let formattedNonTenants = [];
        let formattedTenants = [];
        for (let tenant of totalTenants) {
            if (tenant.haveRented) {
                formattedTenants.push(tenant);
            } else {
                formattedNonTenants.push(tenant);
            }
        }
        setNonTenants(formattedNonTenants);
    }

    const handleSort = (sortType) => {
        setSortBy(sortType)
        setcurrentPage(1)
    }

    const handleSearch = (e) => {
        setSearchVal(e.target.value)
        setcurrentPage(1)
    }


    const handlePageChange = (newPage) => {
        if (newPage > 0) {
            setcurrentPage(newPage);
        }
    }
    // if (loading) return <Loading />
    return (
        <>
            <div className='header_table d-flex justify-content-between align-items-center'>
                <h1>Not Tenants</h1>
                <div className='searchbar_table'>
                    <div>
                        <InputGroup className='input_table_header'>
                            <InputGroup.Text id="basic-addon1">
                                <HiMagnifyingGlass />
                            </InputGroup.Text>
                            <Form.Control
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                value={searchVal}
                                onChange={handleSearch}
                            />
                        </InputGroup>
                    </div>
                    <OverlayTrigger trigger="click" placement="bottom"
                        overlay={
                            <Popover id="popover-basic">
                                <Popover.Body>
                                    <div>
                                        <p>Sort By</p>
                                        <div className='popover_buttons'>
                                            <Dropdown onSelect={handleSort}>
                                                <Dropdown.Toggle style={{ width: "226px", border: '1px solid #c8c8c8ad', borderRadius: '12px' }} variant="light" id="dropdown-basic">
                                                    {sortBy}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item eventKey="Latest" >Latest</Dropdown.Item>
                                                    <Dropdown.Item eventKey="Oldest" >Oldest</Dropdown.Item>
                                                    <Dropdown.Item eventKey="Name [A-Z]" >Name [A-Z]</Dropdown.Item>
                                                    <Dropdown.Item eventKey="Name [Z-A]" >Name [Z-A]</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </Popover.Body>
                            </Popover>}>
                        <button>
                            <GiSettingsKnobs size={22} color='var(--dark)' />
                        </button>
                    </OverlayTrigger>
                </div>

            </div>

            {
                loading ?
                    <Loading /> :
                    <>
                        <NonTenantsList tenants={nonTenants} />
                        <div className=''>
                            <button onClick={() => handlePageChange(+currentPage - 1)} disabled={currentPage === 1}>
                                Previous
                            </button>
                            <span>{`Page ${currentPage} of ${totalPages}`}</span>
                            <button onClick={() => handlePageChange(+currentPage + 1)} disabled={currentPage === totalPages}>
                                Next
                            </button>
                        </div>
                    </>
            }
        </>
    )
}

export default NonTenants