import React, { useContext, useState, useEffect } from 'react'
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { BsPencil } from 'react-icons/bs'
import { ImBin } from 'react-icons/im'
import { Form, Dropdown } from 'react-bootstrap';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Modal from 'react-bootstrap/Modal'
import { NavLink } from 'react-router-dom';
import { GiSettingsKnobs } from 'react-icons/gi'
import { HiMagnifyingGlass } from 'react-icons/hi2'
import InputGroup from 'react-bootstrap/InputGroup';
import { Ucontext } from '../context/Ucontext';
import Loading from '../components/loader/Loading';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';



function EditModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="edit_form_details">
          <Form>
            <Row>

              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicText">
                  <Form.Label>Offer/ Brand Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter Name" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicText">
                  <Form.Label>Discount</Form.Label>
                  <Form.Control type="number" placeholder="Offer %" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicText">
                  <Form.Label>Description</Form.Label>
                  <Form.Control type="text" placeholder="Enter Description" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicText">
                  <Form.Label>Description</Form.Label>
                  <Form.Control type="text" placeholder="Enter Description" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicNumber">
                  <Form.Label>Discount</Form.Label>
                  <Form.Control type="number" placeholder="Offer %" />
                </Form.Group>
              </Col>
            </Row>
            <Row md={10}>
              <Button variant="danger" type="submit">
                Update
              </Button>
            </Row>

          </Form>

        </div>

      </Modal.Body>
    </Modal>
  );
}



const Landlord = () => {

  const {
    adminDispatch,
    adminState,
    getAllLandlords,
    getAllProperties,
    getLandlordProperties,
  } = useContext(Ucontext);

  const { totalLandlords, totalProperties } = adminState;


  const [sortColumn, setSortColumn] = useState(null);
  const [sortType, setSortType] = useState("");
  const [EditShow, setEditShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchVal, setSearchVal] = useState('')
  const [landlordList, setLandlordList] = useState([])
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0)
  // const [sortBy, setSortBy] = useState('Latest')
  const [filter, setFilter] = useState({
    role: '',
    isLandlordInComp: '',
  })


  useEffect(() => {
    loadLandlordsAndProperties(currentPage, searchVal, sortColumn, filter, sortType);
  }, [currentPage, searchVal, sortColumn, filter, sortType])

  useEffect(() => {

  })




  const loadLandlordsAndProperties = async (currentPage, searchVal, sortColumn, filter, sortType) => {
    setLoading(true);
    const { role = "", isLandlordInComp = "" } = filter

    let landlords = await getAllLandlords(currentPage, searchVal, sortColumn, role, isLandlordInComp, sortType);

    console.log("landlords", landlords);
    if (landlords.status == "Success") {
      adminDispatch({ type: "LANDLORDS_LOADED", payload: landlords?.landlords });
      setLandlordList(landlords?.landlords)
      setTotalPages(landlords?.totalPages)
      //setTotalLandlords(landlords?.landlords);
    }
    let properties = await getAllProperties();
    if (properties.status == "success") {
      adminDispatch({ type: "PROPERTIES_LOADED", payload: properties?.properties });
      //setTotalProperties(properties?.properties);
    }
    setLoading(false);
  }




  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <div className='popover_buttons'>

          {/* <button onClick={() => setEditShow(true)} ><BsPencil /> &nbsp; Edit Details</button> */}
          <button style={{ color: 'var(--dark)' }}><ImBin /> &nbsp; Delete</button>
        </div>
      </Popover.Body>
    </Popover>
  );

  // const filter1 = (args) => (
  //   <Popover id="popover-basic">
  //     <Popover.Body>
  //       <div>
  //         <p>Sort By</p>
  //         <div className='popover_buttons'>
  //           <Dropdown onSelect={handleSort}>
  //             <Dropdown.Toggle style={{ width: "226px", border: '1px solid #c8c8c8ad', borderRadius: '12px' }} variant="light" id="dropdown-basic">
  //               {sortBy}
  //             </Dropdown.Toggle>
  //             <Dropdown.Menu >
  //               <Dropdown.Item eventKey="Latest" >Latest</Dropdown.Item>
  //               <Dropdown.Item eventKey="Oldest" >Oldest</Dropdown.Item>
  //               <Dropdown.Item eventKey="Name [A-Z]" >Name [A-Z]</Dropdown.Item>
  //               <Dropdown.Item eventKey="Name [Z-A]" >Name [Z-A]</Dropdown.Item>
  //             </Dropdown.Menu>
  //           </Dropdown>
  //         </div>
  //       </div>
  //     </Popover.Body>
  //   </Popover>
  // );

  const handleSearch = (e) => {
    console.log(e.target.value)
    setSearchVal(e.target.value)
    setcurrentPage(1)
  }


  const handlePageChange = (newPage) => {
    if (newPage > 0) {
      setcurrentPage(newPage);
    }
  }

  const handleClickFilter = (e) => {
    setFilter(prev => ({ ...prev, [e.target.name]: e.target.value }))
    setcurrentPage(1)
  }

  const handleSorting = (name) => {
    if (name == sortColumn) {
      setSortType(prev => prev == 'asc' ? 'desc' : 'asc')
    } else {
      setSortType('asc')
    }
    console.log(name)
    setcurrentPage(1)
    setSortColumn(name);
  }

  console.log(totalLandlords, "totalLandlordstotalLandlords")


  return (
    <>
      <div className='header_table d-flex justify-content-between align-items-center'>
        <h1>Landlord/ Agents</h1>
        <div className='searchbar_table'>
          <div>
            <InputGroup className='input_table_header'>
              <InputGroup.Text id="basic-addon1">
                <HiMagnifyingGlass />
              </InputGroup.Text>
              <Form.Control
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon1"
                value={searchVal}
                onChange={handleSearch}
              />
            </InputGroup>
          </div>
          {/* <OverlayTrigger trigger="click" placement="bottom" overlay={filter1()}>
            <button>
              <GiSettingsKnobs size={22} color='var(--dark)' />
            </button>
          </OverlayTrigger> */}
        </div>

      </div>
      <div className='d-flex justify-content-around'>
        <div>
          <h5>Landlord/Agent</h5>
          <select name="role" id="role" onChange={handleClickFilter}>
            <option value="">All</option>
            <option value="yes">Landlord</option>
            <option value="no">Agent</option>
          </select>
        </div>
        <div>
          <h5>Is Institution</h5>
          <select name="isLandlordInComp" id="isLandlordInComp" onChange={handleClickFilter}>
            <option value="">All</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
      </div>
      {loading ? <Loading /> : null}
      <Table responsive hover>
        <thead>
          <tr>
            <th onClick={e => handleSorting("fname_companyName")}>Name {sortColumn == "fname_companyName" ? sortType == "asc" ? <FaSortUp /> : <FaSortDown /> : <FaSort />}</th>
            <th onClick={e => handleSorting("cif_dni_nie")}>NIF/DNI/NIE {sortColumn == "cif_dni_nie" ? sortType == "asc" ? <FaSortUp /> : <FaSortDown /> : <FaSort />}</th>
            <th>Landlord/Agent</th>
            <th>Is Institution?</th>
            <th onClick={e => handleSorting("property_count")}>No. of Properties {sortColumn == "property_count" ? sortType == "asc" ? <FaSortUp /> : <FaSortDown /> : <FaSort />}</th>
            <th>  </th>
            <th>  </th>
          </tr>
        </thead>
        <tbody>
          {
            totalLandlords && totalLandlords.map((landlord, index) => (
              <tr key={landlord?._id}>
                <td>{landlord?.fname_companyName} {landlord?.lname}</td>
                <td>{landlord?.cif_dni_nie}</td>
                <td>{landlord?.landlordtype}</td>
                <td>{landlord?.isLandlordInComp != 'individual' ? "Yes" : "No"}</td>
                <td>{landlord?.properties.length}</td>
                <td><NavLink to={`/landlorddetail/${landlord?._id}`}>Details</NavLink></td>
                <td><OverlayTrigger trigger="click" placement="left" overlay={popover}>
                  <Button variant="outline-danger" href="#">...</Button>
                </OverlayTrigger></td>
              </tr>
            ))
          }


          <EditModal
            show={EditShow}
            onHide={() => setEditShow(false)}
          />
        </tbody>
      </Table>

      <div className=''>
        <button onClick={() => handlePageChange(+currentPage - 1)} disabled={currentPage === 1}>
          Previous
        </button>
        <span>{`Page ${currentPage} of ${totalPages}`}</span>
        <button onClick={() => handlePageChange(+currentPage + 1)} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    </>
  )
}

export default Landlord