export const initialAdminState = {
  totalTenants: [],
  totalLandlords: [],
  totalProperties: [],
  totalOffers: [],
  adminError: null,
  adminstate: "initial state"
};

export const adminReducer = (state, action) => {
  switch (action.type) {
    case "TENANTS_LOADED":
      return {
        ...state,
        totalTenants: action.payload,
      };
    case "LANDLORDS_LOADED":
      return {
        ...state,
        totalLandlords:action.payload,
      };
    case "PROPERTIES_LOADED":
      return {
        ...state,
        totalProperties:action.payload,
      };
      case "OFFERS_LOADED":
        return {
          ...state,
          totalOffers:action.payload,
        };
    case "ERROR":
      console.log("error dispatched",action.payload)
      return {
        ...state,
        adminError: action.payload
      };
    default:
      return state;
  }
};