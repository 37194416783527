import React, { useEffect, useState, useContext } from 'react'
import Table from 'react-bootstrap/esm/Table'
import { getWaitlist } from '../context/functions/AdminFunction'
import { Form, InputGroup } from 'react-bootstrap'
import { FaFileDownload } from "react-icons/fa";
import { HiMagnifyingGlass } from 'react-icons/hi2'
import CsvDownloadButton from 'react-json-to-csv'



const Waitlist = (props) => {


    const [waitlist, setWaitlist] = useState([])
    const [searchVal, setSearchVal] = useState('')
    const [landlordList, setLandlordList] = useState([])
    const [currentPage, setcurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0)
    const [sortBy, setSortBy] = useState('Latest')
    const [filter, setFilter] = useState({})

    useEffect(() => {
        getWaitlistFunc()
    }, [currentPage, searchVal])

    const getWaitlistFunc = async () => {

        const temp = await getWaitlist(searchVal, currentPage);

        setTotalPages(temp?.totalPages)
        setWaitlist(temp?.waitlist)

        console.log(temp);
    }

    const handleSearch = (e) => {
        console.log(e.target.value)
        setSearchVal(e.target.value)
        setcurrentPage(1)
    }

    const handlePageChange = (newPage) => {
        if (newPage > 0) {
            setcurrentPage(newPage);
        }
    }

    const handleDownloadData = async () => {
        console.log("Function has been called!!")
    }

    return (
        <>

            <div className='header_table d-flex justify-content-between align-items-center'>
                <h1>Zero Deposit Waitlist</h1>
                <div className='searchbar_table'>
                    <div className='d-flex align-items-center gap-4'>
                        <CsvDownloadButton data={waitlist} className='border-0' hover="Download List">
                            <FaFileDownload />
                        </CsvDownloadButton>
                        <InputGroup className='input_table_header'>
                            <InputGroup.Text id="basic-addon1">
                                <HiMagnifyingGlass />
                            </InputGroup.Text>
                            <Form.Control
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                value={searchVal}
                                onChange={handleSearch}
                            />
                        </InputGroup>
                    </div>

                    {/* <OverlayTrigger trigger="click" placement="bottom" overlay={filter1()}>
                        <button>
                            <GiSettingsKnobs size={22} color='var(--dark)' />
                        </button>
                    </OverlayTrigger> */}
                </div>

            </div>
            <Table responsive hover>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Added On</th>
                    </tr>
                </thead>
                <tbody>

                    {
                        waitlist && waitlist.map((val, index) => (
                            <tr key={index}>
                                <td>{val.email}</td>
                                <td>{val.created_at}</td>
                            </tr>
                        ))
                    }

                </tbody>
            </Table>
            <div className=''>
                <button onClick={() => handlePageChange(+currentPage - 1)} disabled={currentPage === 1}>
                    Previous
                </button>
                <span>{`Page ${currentPage} of ${totalPages}`}</span>
                <button onClick={() => handlePageChange(+currentPage + 1)} disabled={currentPage === totalPages}>
                    Next
                </button>
            </div>
        </>
    )
}

export default Waitlist