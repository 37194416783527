import React, { useEffect, useState } from 'react'

const LandLordCard = (props) => {
  const { landlord, properties, sharedProfile } = props;

  const [filteredProperties, setFilteredProperties] = useState([])

  useEffect(() => {

    const getPropertyIdsFromSharedProfiles = sharedProfiles => {
      return sharedProfiles.reduce((acc, profile) => {
        return acc.concat(profile.propertyid.map(property => property._id));
      }, []);
    };

    const propertyIdsFromSharedProfiles = getPropertyIdsFromSharedProfiles(sharedProfile);

    const filteredProperties2 = properties.filter(property => {
      return propertyIdsFromSharedProfiles.some(id => id === property._id);
    });

    const filteredProperties1 = filteredProperties2.map(property => property._id)

    setFilteredProperties(filteredProperties1)

  }, [sharedProfile])

  return (
    <>
      <div className='d-flex card'>
        <div className='card-body crd_card d-flex justify-content-between align-items-center flex-wrap'>
          <p><span>Name:</span><br></br>{landlord?.fname_companyName} {landlord?.lname}</p>
          <p><span>Ph.no:</span><br></br>{landlord?.phone}</p>
          <p><span>Type:</span><br></br>{landlord?.landlordtype}</p>
          <p><span>CIF:</span><br></br>{landlord?.cif_dni_nie}</p>
          <p><span>Linked Properties</span><br></br>{filteredProperties.length}</p>
          <p><span>No. of Properties</span><br></br>{properties.length}</p>
          <p><span>Company:</span><br></br>{landlord?.company_name}</p>
          <p><span>Email:</span><br></br>{landlord?.email}</p>
        </div>
      </div>
    </>
  )
}

export default LandLordCard