import React, { useEffect, useState, useContext } from 'react'
import { GiSettingsKnobs } from 'react-icons/gi'
import { HiMagnifyingGlass } from 'react-icons/hi2'
import { InputGroup, Dropdown, Popover, OverlayTrigger, Form } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import TenantsList from '../components/tenants/TenantsList';
import NonTenantsList from '../components/tenants/NonTenantsList';
import { Ucontext } from '../context/Ucontext';
import Loading from '../components/loader/Loading';



const Tenants = () => {
  const {
    adminDispatch,
    getSingleUser,
    adminState,
    getAllTenants,

  } = useContext(Ucontext);
  const { totalTenants, totalProperties } = adminState;

  const [sortColumn, setSortColumn] = useState(null);
  const [sortType, setSortType] = useState("");
  const [searchVal, setSearchVal] = useState('')
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0)
  const [sortBy, setSortBy] = useState('Latest')
  const [filter, setFilter] = useState({
    digital_verification: "",
    bank_verified: "",
    have_guarantor: "",
    guarantor_scored: "",
    linked_property: ""
  })

  const location = useLocation();
  // console.log(location.pathname);

  const [routeName, setRouteName] = useState();
  const [tenants, setTenants] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    loadTenants(currentPage, searchVal, sortBy, filter);
  }, [currentPage, searchVal, sortBy, filter, sortColumn, sortType])

  useEffect(() => {
    checkRented();
  }, [totalTenants])


  const loadTenants = async (currentPage, searchVal, sortBy, filters) => {
    setLoading(true);
    const { digital_verification = "", bank_verified = "", have_guarantor = "", guarantor_scored = "", linked_property = "" } = filter

    let tenants = await getAllTenants(currentPage, searchVal, 'rented', sortColumn, digital_verification, bank_verified, have_guarantor, guarantor_scored, linked_property, sortType);
    if (tenants.status == "Success") {
      await adminDispatch({ type: "TENANTS_LOADED", payload: tenants?.tenants });
      setTotalPages(tenants.totalPages)
    }
    setLoading(false);

  }

  const checkRented = async () => {
    setTenants(totalTenants);
  }

  const handleSort = (sortType) => {
    console.log("HANDLESORT", sortType)
    setSortBy(sortType)
    setcurrentPage(1)
  }

  const handleSearch = (e) => {
    console.log(e.target.value)
    setSearchVal(e.target.value)
    setcurrentPage(1)
  }


  const handlePageChange = (newPage) => {
    if (newPage > 0) {
      setcurrentPage(newPage);
    }
  }

  const handleClickFilter = (e) => {
    console.log(e.target.name)
    setFilter(prev => ({ ...prev, [e.target.name]: e.target.value }))
    setcurrentPage(1)
  }

  const handleSorting = (name) => {
    if (name == sortColumn) {
      setSortType(prev => prev == 'asc' ? 'desc' : 'asc')
    } else {
      setSortType('asc')
    }
    console.log(name)
    setcurrentPage(1)
    setSortColumn(name);
  }

  return (
    <>
      <div className='header_table d-flex justify-content-between align-items-center'>

        <h1>Tenants</h1>


        <div className='searchbar_table'>
          <div>
            <InputGroup className='input_table_header'>
              <InputGroup.Text id="basic-addon1">
                <HiMagnifyingGlass />
              </InputGroup.Text>
              <Form.Control
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon1"
                value={searchVal}
                onChange={handleSearch}
              />
            </InputGroup>
          </div>
          {/* <OverlayTrigger trigger="click" placement="bottom"
            overlay={
              <Popover id="popover-basic">
                <Popover.Body>
                  <div>
                    <p>Sort By</p>
                    <div className='popover_buttons'>
                      <Dropdown onSelect={handleSort}>
                        <Dropdown.Toggle style={{ width: "226px", border: '1px solid #c8c8c8ad', borderRadius: '12px' }} variant="light" id="dropdown-basic">
                          {sortBy}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item eventKey="Latest" >Latest</Dropdown.Item>
                          <Dropdown.Item eventKey="Oldest" >Oldest</Dropdown.Item>
                          <Dropdown.Item eventKey="Name [A-Z]" >Name [A-Z]</Dropdown.Item>
                          <Dropdown.Item eventKey="Name [Z-A]" >Name [Z-A]</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </Popover.Body>
              </Popover>}>
            <button>
              <GiSettingsKnobs size={22} color='var(--dark)' />
            </button>
          </OverlayTrigger> */}
        </div>

      </div>
      <div className='d-flex justify-content-around'>
        <div>
          <h5>Digital Verification</h5>
          <select name="digital_verification" id="digital_verification" onChange={handleClickFilter}>
            <option value="">All</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
        <div>
          <h5>Bank Verified</h5>
          <select name="bank_verified" id="bank_verified" onChange={handleClickFilter}>
            <option value="">All</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
        <div>
          <h5>Have Guarantor</h5>
          <select name="have_guarantor" id="have_guarantor" onChange={handleClickFilter}>
            <option value="">All</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
        <div>
          <h5>Guarantor Scored</h5>
          <select name="guarantor_scored" id="guarantor_scored" onChange={handleClickFilter}>
            <option value="">All</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
        <div>
          <h5>Linked Property</h5>
          <select name="linked_property" id="linked_property" onChange={handleClickFilter}>
            <option value="">All</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
      </div>
      {
        loading ?
          <Loading /> :
          <>
            <TenantsList tenants={tenants} sortColumn={sortColumn} setSortColumn={setSortColumn} sortType={sortType} setSortType={setSortType} handleSorting={handleSorting} />
            <div className=''>
              <button onClick={() => handlePageChange(+currentPage - 1)} disabled={currentPage === 1}>
                Previous
              </button>
              <span>{`Page ${currentPage} of ${totalPages}`}</span>
              <button onClick={() => handlePageChange(+currentPage + 1)} disabled={currentPage === totalPages}>
                Next
              </button>
            </div>
          </>
      }
    </>
  )
}

export default Tenants