import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { RiDashboardLine, RiLogoutBoxLine } from "react-icons/ri";
import { FiUsers } from "react-icons/fi";
import { BsHouse, BsHouseDoor } from "react-icons/bs";
import { TbDiscount } from "react-icons/tb";
import "bootstrap/dist/css/bootstrap.min.css";
import Accordion from "react-bootstrap/Accordion";
import { Ucontext } from "../context/Ucontext";

const Sidebar = () => {
  const { authState, authDispatch, login, logout } = useContext(Ucontext);

  const logoutAdmin = () => {
    logout();
    authDispatch({ type: "LOGOUT", payload: "logged out" });
  };
  return (
    <>
      <div className="sidebar-panel">
        <div className="sidebarhead">
          <Link className="logobar">
            <img src="/images/logo.svg" alt="logo" />
          </Link>
          <ul>
            <li>
              <NavLink
                onClick={() => document.body.classList.remove("main")}
                to="/"
              >
                <RiDashboardLine size={20} />
                Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={() => document.body.classList.remove("main")}
                to="/landlord"
              >
                <BsHouseDoor size={20} />
                Landlord/ Agent
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={() => document.body.classList.remove("main")}
                to={`/tenants`}
              >
                <FiUsers size={20} />
                Tenants
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={() => document.body.classList.remove("main")}
                to={`/waitlist`}
              >
                <FiUsers size={20} />
                Waitlist
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={() => document.body.classList.remove("main")}
                to={`/transactions/rentalInfo`}
              >
                <FiUsers size={20} />
                Tenant Rental Transaction
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={() => document.body.classList.remove("main")}
                to={`/transactions/assigned`}
              >
                <FiUsers size={20} />
                Assigned Properties Transaction 
              </NavLink>
            </li>

            <li>
              <Accordion>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <BsHouse size={20} /> Properties
                  </Accordion.Header>
                  <Accordion.Body>
                    <NavLink
                      onClick={() => document.body.classList.remove("main")}
                      to="/rentprop"
                    >
                      Rented Properties
                    </NavLink>
                    <NavLink
                      onClick={() => document.body.classList.remove("main")}
                      to="/unrentprop"
                    >
                      Unrented Properties
                    </NavLink>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </li>

            <li>
              <Link onClick={logoutAdmin} to="/login">
                <RiLogoutBoxLine size={20} />
                Logout
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
