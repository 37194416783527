import React, { useEffect, useState, useContext } from "react";
import Table from "react-bootstrap/esm/Table";
import { loadTransactions } from "../context/functions/AdminFunction";
import { Form, InputGroup } from "react-bootstrap";
import { FaFileDownload } from "react-icons/fa";
import { HiMagnifyingGlass } from "react-icons/hi2";
import CsvDownloadButton from "react-json-to-csv";

const TransactionsAssigned = (props) => {
  const [transactions, setTransactions] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    getTransactionsFunc();
  }, [currentPage, searchVal]);

  const getTransactionsFunc = async () => {
    const temp = await loadTransactions(
      "assignedProps",
      searchVal,
      currentPage
    );

    setTotalPages(temp?.totalPages);
    setTransactions(temp?.transactions);
    console.log(temp);
  };

  const handleSearch = (e) => {
    console.log(e.target.value);
    setSearchVal(e.target.value);
    setcurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0) {
      setcurrentPage(newPage);
    }
  };

  return (
    <>
      <div className="header_table d-flex justify-content-between align-items-center">
        <h1>Transactions</h1>
        <div className="searchbar_table">
          <div className="d-flex align-items-center gap-4">
            <InputGroup className="input_table_header">
              <InputGroup.Text id="basic-addon1">
                <HiMagnifyingGlass />
              </InputGroup.Text>
              <Form.Control
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon1"
                value={searchVal}
                onChange={handleSearch}
              />
            </InputGroup>
          </div>
        </div>
      </div>
      <Table responsive hover>
        <thead>
          <tr>
            <th>Landlord Name</th>
            <th>Address</th>
            <th>Amount</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {transactions &&
            transactions.map((val, index) => (
              <tr key={index}>
                <td>
                  {val.landlord.fname_companyName || ""}{" "}
                  {val.landlord.lname || ""}
                </td>
                <td>{val.address}</td>
                <td>{val.amount}</td>
                <td>{val.type}</td>
              </tr>
            ))}
        </tbody>
      </Table>
      <div className="">
        <button
          onClick={() => handlePageChange(+currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>{`Page ${currentPage} of ${totalPages}`}</span>
        <button
          onClick={() => handlePageChange(+currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default TransactionsAssigned;
