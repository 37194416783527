
import { Outlet} from 'react-router-dom'
import Sidebar from '../Sidebar';




function AuthLayout() {
  return (
    <>
    
    <div className="main_dashboard_block">
        <div className="dashboard_wrapper">
          
          <div className="Authoutlet"> 
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}

export default AuthLayout;
