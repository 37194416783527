import React from 'react'
import { Outlet} from 'react-router-dom'
import Sidebar from '../Sidebar';
import Navbar from 'react-bootstrap/Navbar';


const Tablelayout = () => {
  return (
    <>

<div className="main_dashboard_block">
    <div className='top_navbar'>
            <Navbar collapseOnSelect fixed="top" expand="xl" className='' variant="light">
          <Navbar.Brand className='px-5' href="/"><img src='/images/logo.svg'/></Navbar.Brand>
          <Navbar.Toggle onClick={()=> document.body.classList.toggle('main')} className='mx-4' aria-controls="responsive-navbar-nav" />
      </Navbar>
            </div>
        <div className="dashboard_wrapper">
          <div className='dashboardsidebar'>
            <Sidebar />
          </div>
          
          <div className="tableoutlet">
            <Outlet />
          </div>
        </div>

      </div>

    </>
  )
}

export default Tablelayout;