import React, { useState, useEffect, useContext } from 'react'
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal';
import { AiOutlineHistory } from 'react-icons/ai'
import { ImBin } from 'react-icons/im'
import { MdLocationPin } from 'react-icons/md'
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { Link, useLocation } from 'react-router-dom';
import { GiSettingsKnobs } from 'react-icons/gi'
import { HiMagnifyingGlass } from 'react-icons/hi2'
import { InputGroup, Dropdown, Popover, OverlayTrigger, Form } from 'react-bootstrap';
import moment from 'moment';
import { Ucontext } from '../../context/Ucontext';






const RentedProp = (props) => {
    const { properties } = props;

    const {
        getFeedbackDetails
    } = useContext(Ucontext);

    const [RentShow, setRentShow] = useState(false);
    const [FeedbackShow, setFeedbackShow] = useState(false)
    const [singleProperty, setSingleProperty] = useState();
    const [hasTenants, setHasTenants] = useState(false)

    const [feedback, setFeedback] = useState({
        haveFeedback: false,
        tenant_name: "",
        stars: 0,
        text: "",
    });


    const viewPropDetail = (value) => {
        console.log(value);
        //loadSingleTenant(tenant._id,"tenant")}

        setSingleProperty(value);

        if (value.previous_tenants.length != 0) {
            value.previous_tenants.forEach(data => {
                if (data.tenantid != null) {
                    setHasTenants(true)
                    return
                }
            })

        }

        setRentShow(true);

    }

    const calculateDate = (startdate, enddate) => {

        let leaseStart = moment(startdate);
        let leaseCurr = moment(enddate);
        let timeCalculated = leaseCurr - leaseStart
        //return (Math.ceil(timeCalculated / (3600 * 24000 * 30))); //60*60*24*1000  in days
        return `${leaseCurr.diff(leaseStart, 'months')} month(s)`;
    }


    const popover = (
        <Popover id="popover-basic">
            <Popover.Body>
                <div className='popover_buttons'>
                    {/* <button><AiOutlineHistory /> &nbsp; Rent Details</button> */}
                    {/* <button><AiOutlineHistory /> &nbsp; History</button> */}
                    <button style={{ color: 'var(--dark)' }}><ImBin /> &nbsp; Delete</button>
                </div>
            </Popover.Body>
        </Popover>
    );

    const changeDateFormat = (date) => {

        if (date) {
            let d = new Date(date)
            return d.toISOString().substring(0, 10);
        }
        else {
            return "no info"
        }
    }

    const handleFeedbackClick = async (tenantId, propId) => {
        try {
            console.log('propId',propId)
            const result = await getFeedbackDetails(tenantId, propId)
            console.log(result)
            setFeedback({
                ...feedback,
                haveFeedback: true,
                stars: result.feedback.rating,
                text: result.feedback.feedback_text,
                tenant_name: result.feedback.tenant_name,
            });

        }catch(err){
            setFeedback({...feedback,haveFeedback:false,stars:0,text:"No Feedback Recorded"})
        }
        setFeedbackShow(true)
    }


    return (
        <>
            <Table responsive hover>
                <thead>
                    <tr>
                        <th>Address</th>
                        <th>Postcode</th>
                        <th>Muncipality</th>
                        <th>Rent</th>
                        <th>Tenant</th>
                        <th>Landlord/ Agent</th>

                        <th>  </th>
                        <th>  </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        properties && properties.map((property, index) => (
                            <tr key={index}>
                                <td>{property?.address}</td>
                                <td>{property?.postcode}</td>
                                <td>{property?.muncipality}</td>
                                <td>{1000}</td>
                                <td>{property?.current_tenant?.fname} {property?.current_tenant?.lname}</td>
                                <td>{property?.landlord?.fname_companyName} {property?.landlord?.lname}</td>
                                <td><a onClick={() => viewPropDetail(property)}>Property History</a></td>
                                <td><OverlayTrigger trigger="click" placement="left" overlay={popover}>
                                    <Button variant="outline-danger" href="#">...</Button>
                                </OverlayTrigger></td>
                            </tr>

                        ))

                    }
                </tbody>
            </Table>
            {/* view feedback modal */}
            <Modal
                show={FeedbackShow}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header onClick={(() => setFeedbackShow(false))} closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Feedback
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <p>{props.img}</p> */}
                    <div className='modal_body_star'>
                        <span className={`fa fa-star ${feedback.stars >= 1 ? 'checked' : ''} `}></span>
                        <span className={`fa fa-star ${feedback.stars >= 2 ? 'checked' : ''} `}></span>
                        <span className={`fa fa-star ${feedback.stars >= 3 ? 'checked' : ''} `}></span>
                        <span className={`fa fa-star ${feedback.stars >= 4 ? 'checked' : ''} `}></span>
                        <span className={`fa fa-star ${feedback.stars >= 5 ? 'checked' : ''} `}></span></div>
                    <p>{feedback.text}</p>
                </Modal.Body>
            </Modal>

            <Modal
                show = {RentShow}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header onClick={() => { setRentShow(false);setHasTenants(false); }} closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Property History
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5 className='modal_h5 d-flex align-items-center'> <MdLocationPin size={20} color='var(--dark)' />
                        {singleProperty?.address}, {singleProperty?.muncipality}, {singleProperty?.province}
                    </h5>
                    {
                        hasTenants == true ?
                            singleProperty?.previous_tenants && singleProperty?.previous_tenants.map((tenant, index) => (
                                tenant?.tenantid != null &&
                                    <div className='property_modal' key={tenant._id}>
                                        <Row>
                                            <p>Tenant Name. <span>{tenant?.tenantid?.fname} {tenant?.tenantid?.lname}</span></p>
                                        </Row>
                                        <Row>
                                            <p>Tenant Id. <span>{tenant.tenantid?._id}</span></p>
                                        </Row>
                                        <Row>
                                            <Col className='text-end' md={4}>
                                                <Link color='var(--dark)' onClick={() => handleFeedbackClick(tenant.tenantid?._id, singleProperty._id)}>View Feedback</Link>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <p className='text-center'>{changeDateFormat(tenant.lease_started_on)} - {changeDateFormat(tenant.lease_ended_on)}. {calculateDate(tenant.lease_started_on, tenant.lease_ended_on)}</p>
                                        </Row>
                                    </div>
                            ))
                            :
                            <p>No records found</p>
                    }


                </Modal.Body>
            </Modal>
        </>

    )
}

export default RentedProp