import React, { useEffect, useState, useContext } from 'react'
import { NavLink, Link, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/esm/Table'
import { Row, Col, Button, Modal } from 'react-bootstrap'
import { IoIosArrowBack } from 'react-icons/io'
import { GiSettingsKnobs } from 'react-icons/gi'
import { MdLocationPin } from 'react-icons/md'
import { HiMagnifyingGlass } from 'react-icons/hi2'
import { InputGroup, Dropdown, Popover, OverlayTrigger, Form } from 'react-bootstrap';
import { Ucontext } from '../../context/Ucontext'






const Properties = (props) => {

  const {
    getFeedbackDetails
  } = useContext(Ucontext);

  const { properties, sharedProfile } = props;


  const { landlordid } = useParams();
  const [filteredProperties, setFilteredProperties] = useState([])
  const [filteredPropertiesDetails, setFilteredPropertiesDeatils] = useState([])

  const connectedSharedProfiles = async (propertyId) => {
    try {
      // Assuming sharedProfile is fetched asynchronously
      const profiles = await sharedProfile;

      // Filter the profiles based on propertyId
      const filteredProfiles = profiles.filter(profile =>
        profile.propertyid.some(property => property._id === propertyId)
      );

      return filteredProfiles;
    } catch (error) {
      console.error(error);
      return []; // or handle the error accordingly
    }
  };
  useEffect(() => {

  const getPropertyIdsFromSharedProfiles = sharedProfiles => {
      return sharedProfiles.reduce((acc, profile) => {
        return acc.concat(profile.propertyid.map(property => property._id));
      }, []);
    };

    const propertyIdsFromSharedProfiles = getPropertyIdsFromSharedProfiles(sharedProfile);

    const filteredProperties2 = properties.filter(property => {
      return propertyIdsFromSharedProfiles.some(id => id === property._id);
    });

    const filteredProperties1 = filteredProperties2.map(property => property._id)

    setFilteredProperties(filteredProperties1)

  }, [sharedProfile])

  const getClassName = (value) => {
    if (value) {
      return "green"
    } else {
      return "red";
    }
  }
  const [RentShow, setRentShow] = useState(false)
  const [FeedbackShow, setFeedbackShow] = useState(false)
  const [linkedTenants, setLinkedTenants] = useState({})
  const [propHistory, setPropHistory] = useState({})
  const [hasTenants, setHasTenants] = useState(false)

  const [feedback, setFeedback] = useState({
    haveFeedback: false,
    tenant_name: "",
    stars: 0,
    text: "",
  });

  const handlePropViewDetails = async (property) => {
    setPropHistory(property)
    const temp = await connectedSharedProfiles(property?._id);
    console.log(temp)
    setLinkedTenants(temp)
    setRentShow(true)
  }


  return (
    <>
      <Table responsive hover>
        <thead>
          <tr>
            <th>Street</th>
            <th>Street Number</th>
            <th>Floor Number</th>
            <th>Door</th>
            <th>Postcode</th>
            <th>Muncipality</th>
            <th>Status</th>
            <th>  </th>
          </tr>
        </thead>
        <tbody>

          {
            properties && properties.map((property, index) => (
              <tr key={index}>
                <td>{property.street}</td>
                <td>{property.street_number}</td>
                <td>{property.floor_number}</td>
                <td>{property.flat_number}</td>
                <td>{property.postcode}</td>
                <td>{property.muncipality}</td>
                {/* className="red" */}
                <td className={getClassName(filteredProperties.includes(property?._id))} >
                  {filteredProperties.includes(property?._id) ? "Linked" : "Not Linked"}
                </td>
                <td><a onClick={() => handlePropViewDetails(property)}>View Details</a></td>
              </tr>
            ))
          }

        </tbody>
      </Table>
      {/* view feedback modal */}
      <Modal
        show={FeedbackShow}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header onClick={(() => setFeedbackShow(false))} closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Feedback
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <p>{props.img}</p> */}
          <div className='modal_body_star'>
            <span className={`fa fa-star ${feedback.stars >= 1 ? 'checked' : ''} `}></span>
            <span className={`fa fa-star ${feedback.stars >= 2 ? 'checked' : ''} `}></span>
            <span className={`fa fa-star ${feedback.stars >= 3 ? 'checked' : ''} `}></span>
            <span className={`fa fa-star ${feedback.stars >= 4 ? 'checked' : ''} `}></span>
            <span className={`fa fa-star ${feedback.stars >= 5 ? 'checked' : ''} `}></span></div>
          <p>{feedback.text}</p>
        </Modal.Body>
      </Modal>
      {/* view property modal */}
      <Modal
        show={RentShow}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header onClick={() => { setRentShow(false); setHasTenants(false); }} closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Property Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className='modal_h5 d-flex align-items-center'> <MdLocationPin size={20} color='var(--dark)' /> {propHistory.street} - {propHistory.street_number},{propHistory.floor_number} - {propHistory.flat_number}, {propHistory.postcode} </h5>
          {
            Array.isArray(linkedTenants) && linkedTenants.length > 0 ?
              <>
                <h6>Linked Users</h6>
                <ul>
                  {
                    linkedTenants.map(tenant => (
                      <li>{tenant?.tenantid?.fname} {tenant?.tenantid?.lname}</li>
                    ))
                  }
                </ul>
              </>
              :
              <h6>No Linked Users</h6>
          }
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Properties