import React, { useContext, useState } from 'react';
import { Ucontext } from '../context/Ucontext';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/esm/Container";
import { BsLinkedin } from 'react-icons/bs';
import toast from 'react-hot-toast';


const Login = () => {
  const {
    authState,
    authDispatch,
    login,
    logout,
  } = useContext(Ucontext);
  const { ustate, isLoggedIn } = authState;

  const [admin, setAdmin] = useState({
    email: "",
    password: "",
    type: "super-admin"
  })

  const onChange = (e) => {
    const { name, value } = e.target;
    setAdmin({ ...admin, [name]: value });
  }



  const onSubmit = async (e) => {
    e.preventDefault();
    // let userData = {
    //   "email":"admin@gmail.com",
    //   "password":"Hello123@",
    //   "type":"super-admin"
    // }
    console.log("admin login info", admin)
    const loginRes = await login(admin);
    // console.log(loginRes);
    if (loginRes?.auth) {
      localStorage.setItem('token', loginRes.token);
      authDispatch({ type: "LOGIN", payload: loginRes.user });
      toast.success("Logged In successfully")
    } else {
      if(loginRes?.response?.data){
        toast.error(loginRes?.response?.data?.message);
      }else{
        toast.error(loginRes?.message);
      }
      
    }

  }

  return (
    <>
      <div className='Auth-wrapper'>
        <div className="Auth-bar">
          <div className="logobarr">
            <Container className="p-0 d-flex justify-content-start align-items-top gap-10">
              <img src='/images/logo.svg' alt='logo' />
              <h1 className='mb-0'>Admin</h1>

            </Container>
          </div>

          <div className="authbar">
            <Container>
              <div className="authbar-inner">
                <div className="leftbar">
                  <h2>Let’s get started!</h2>
                </div>
                <div className="rightbar">
                  <div className="right-contentbar">
                    <h1>Log In</h1>

                    <form onSubmit={onSubmit}>
                      <div className="auth-form">
                        <input type="text" className="form-control user-auth" name="email" placeholder="Email" onChange={onChange} />
                        <input type="password" className="form-control user-auth border-0" name="password" placeholder="Password" onChange={onChange} />
                        <Button className="btn-fill" type="submit">
                          Login
                        </Button>
                      </div>

                    </form>
                  </div>
                  <img className='logo-img' src='../images/logo.png' alt='logo' />
                </div>
              </div>
            </Container>
          </div>

        </div>



      </div>
    </>
  )
}

export default Login