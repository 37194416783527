import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { AiFillStar } from 'react-icons/ai'
import { ImBin } from 'react-icons/im'
import { IoIosRefresh } from 'react-icons/io'
import { InputGroup, Dropdown, Popover, OverlayTrigger, Form } from 'react-bootstrap';

const NonTenantsList = (props) => {

    const {tenants} = props;
    const [RentModal, setRentModal] = useState(false);
    const [singleTenant, setSingleTenant] = useState();


    const popover = (
        <Popover id="popover-basic">
            <Popover.Body>
                <div className='popover_buttons'>

                    <button><IoIosRefresh /> &nbsp; Send Re-Verification</button>
                    <button style={{ color: 'var(--dark)' }}><ImBin /> &nbsp; Delete</button>
                </div>
            </Popover.Body>
        </Popover>
    );

    function MyVerticallyCenteredModal(props) {
        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        User Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="user_details">
                            <Row>
                                <Col>
                                    <p>Name</p>
                                    <h5>{singleTenant?.fname} {singleTenant?.lname}</h5>
                                </Col>
                                <Col>
                                    <p>DNI/NIE</p>
                                    <h5>{singleTenant?.dni_nie}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>Type Of Employee:</p>
                                    <h5>{singleTenant?.type_of_employee}</h5>
                                </Col>
                                <Col>
                                    <p>Nationality:</p>
                                    <h5>{singleTenant?.nationality}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>Name Of Company:</p>
                                    <h5>{singleTenant?.name_of_company}</h5>
                                </Col>
                                <Col>
                                    <p>Employment Status:</p>
                                    <h5>{singleTenant?.employment_status}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>University:</p>
                                    <h5>{singleTenant?.name_of_university}</h5>
                                </Col>
                                <Col>
                                    <p>Education:</p>
                                    <h5>{singleTenant?.type_of_student}</h5>
                                </Col>
                            </Row>
                            {
                                singleTenant && singleTenant.previousFeedback && singleTenant.previousFeedback.rating &&
                                <Row>
                                    <Col>
                                        <p>Previous Landlord Rating:</p>
                                        <h5 style={{ display: 'flex', alignItems: 'center' }}>{singleTenant.previousFeedback.rating}<AiFillStar color='#F59F18' size={20} /></h5>
                                    </Col>
                                </Row>

                            }
                        </div>

                </Modal.Body>
            </Modal>
        );
    }



    const getKycStatus = (value) =>{
        if(value){
            return <p style={{"color":"green"}}>Completed</p>
        }else{
            return <p style={{"color":"red"}}>Not completed</p>
        }
    }

    const viewDetail = (value) => {
        console.log(value);
        //loadSingleTenant(tenant._id,"tenant")}
        setSingleTenant(value);
        setRentModal(true);

    }



    return (
        <>
            <Table responsive hover>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>UNO Id</th>
                        <th>UNO Score</th>
                        <th>Phone No.</th>
                        <th >Email</th>
                        <th>KYC</th>
                        <th>  </th>
                        <th>  </th>
                    </tr>
                </thead>
                <tbody>

                    {
                        tenants.map((tenant, index) => (
                            <tr key={index}>
                                <td>{tenant?.fname} {tenant?.lname}</td>
                                <td>{tenant?.uno_id}</td>
                                <td>{tenant?.paymentScores?.creditScore?.value}</td>
                                <td>{tenant?.phone_number}</td>
                                <td>{tenant?.email}</td>
                                <td>{getKycStatus(tenant?.onfido_kyc_success)}</td>
                                <td><a onClick={() => viewDetail(tenant)}>View Details</a></td>
                                <td><OverlayTrigger trigger="click" placement="left" overlay={popover}>
                                    <Button variant="outline-danger" href="#">...</Button>
                                </OverlayTrigger></td>
                            </tr>
                        ))

                    }
                    <MyVerticallyCenteredModal
                        show={RentModal}
                        onHide={() => setRentModal(false)}
                    />

                </tbody>
            </Table>
        </>
    )
}

export default NonTenantsList